import React from "react";

import AccountsPalworld from "@/game-palworld/Accounts.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";

export default function PalworldSettings() {
  return (
    <PageContainer>
      <AccountsPalworld />
    </PageContainer>
  );
}
