import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import definition from "@/game-palworld/definition.mjs";
import { getProfileAvatar } from "@/game-palworld/utils.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

// These are not real types for Palworld, when we have blitz-messages from Palworld then the types can be declared properly
type Profile = {
  name: string;
  id: string;
  iconId: string;
};

export default function AccountsPalworld() {
  const state = useSnapshot(readState);
  // @ts-ignore Trying to assign the correct types to this causes an error
  const palworld: { [id: string]: Profile } | undefined =
    state.settings.loggedInAccounts[definition.shortName];
  const profiles = useMemo(
    () =>
      Object.entries(palworld ?? {}).map(([, profile]: [string, Profile]) => ({
        displayName: profile.name,
        key: profile.id,
        avatar: getProfileAvatar(profile.iconId),
      })),
    [palworld],
  );
  return (
    <Accounts
      profiles={profiles}
      gameShort={definition.shortName}
      autoDetectText={[
        "palworld:settings.noSettings",
        "There are no settings for Palworld yet, check back later!",
      ]}
      // autoDetectText={[
      //   "palworld:settings.autoDetectProfile",
      //   "Hey! I can automatically detect your profile. Just open Palworld and log into the account you want to connect.",
      // ]}
      renderOtherActionItems={undefined}
      renderOtherItems={undefined}
    />
  );
}
